import React from 'react'

import Collapsible from './Collapsible'
import SlotMaterialMenu from './SlotsMaterialsMenu'
import { Menu } from './styles'
import type { SceneTree, ViewerRef } from './types'

interface SlotsModelsProps {
  sceneTree: SceneTree
  viewerRef: ViewerRef
  onChange: (sceneTree: SceneTree) => void
}

const SlotsModelsMenu: React.FC<SlotsModelsProps> = ({
  sceneTree,
  viewerRef,
  onChange,
}: SlotsModelsProps) => (
  <>
    <SlotMaterialMenu
      sceneTree={sceneTree}
      onChange={onChange}
      viewerRef={viewerRef}
    />
    {sceneTree?.components.map((comp) => (
      <Collapsible key={comp.name} title={comp.name}>
        <Menu>
          {comp.options.map((m) => (
            <React.Fragment key={comp.name.concat(m)}>
              <input
                id={comp.name.concat(m)}
                type="radio"
                name={comp.name}
                value={m}
                checked={comp.active?.id === m}
                onChange={(event) => {
                  viewerRef.current?.setModel({
                    modelId: sceneTree.id,
                    slot: comp.name,
                    modelName: event.target.value,
                  })

                  if (viewerRef.current) {
                    onChange(viewerRef.current.getSceneTree())
                  }
                }}
              />
              <label htmlFor={comp.name.concat(m)} title={m}>
                {m.length > 8 ? m.slice(0, 7).concat('...') : m}
              </label>
            </React.Fragment>
          ))}
        </Menu>

        {comp.active ? (
          <SlotsModelsMenu
            sceneTree={comp.active}
            viewerRef={viewerRef}
            onChange={onChange}
          />
        ) : null}
      </Collapsible>
    ))}
  </>
)

export default SlotsModelsMenu
