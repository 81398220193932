import React from 'react'

import Collapsible from './Collapsible'
import { Menu } from './styles'
import { SceneTree, ViewerRef } from './types'

interface Props {
  sceneTree: SceneTree
  onChange: (sceneTree: SceneTree) => void
  viewerRef: ViewerRef
}

const SlotMaterialMenu: React.FC<Props> = ({
  sceneTree,
  onChange,
  viewerRef,
}: Props) => (
  <>
    {sceneTree.slotsMaterials.map((sMat) => (
      <Collapsible key={sceneTree.id.concat(sMat.name)} title={sMat.name}>
        <Menu>
          {sMat.materials.map((mat) => (
            <React.Fragment key={sceneTree.id.concat(sMat.name.concat(mat))}>
              <input
                id={sceneTree.id.concat(sMat.name.concat(mat))}
                type="radio"
                name={sMat.name}
                value={mat}
                checked={
                  (
                    sMat as {
                      name: string
                      active: string
                      materials: string[]
                    }
                  ).active === mat
                }
                onChange={(event) => {
                  viewerRef.current?.setMaterial({
                    modelId: sceneTree.id,
                    slot: sMat.name,
                    materialName: event.target.value,
                  })

                  if (viewerRef.current) {
                    onChange(viewerRef.current.getSceneTree())
                  }
                }}
              />
              <label
                htmlFor={sceneTree.id.concat(sMat.name.concat(mat))}
                title={mat}
              >
                {mat.length > 8 ? mat.slice(0, 7).concat('...') : mat}
              </label>
            </React.Fragment>
          ))}
        </Menu>
      </Collapsible>
    ))}
  </>
)

export default SlotMaterialMenu
