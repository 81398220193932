import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  width: 100vw;

  background-color: #82c0cc;
  font-family: 'futura-pt', sans-serif;

  display: grid;
  grid-template-columns: 30% auto;

  & > * {
    padding: 20px;
    min-height: 0;
    min-width: 0;
  }

  .left {
    display: flex;
    flex-direction: column;

    .powered-by {
      color: #272727;

      img {
        filter: contrast(0.85) brightness(0.85);
      }
    }

    .dropzone {
      color: #272727;

      background-color: #d6d6d6;
      border-radius: 10px;

      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;

      .background {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        border: 2px solid rgba(72, 134, 181, 1);
        background-color: rgba(72, 134, 181, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-x: none;
    overflow-y: scroll;

    .file-info {
      margin: 5px;
      background-color: #ebebeb;
    }
  }
`
