import React, { useEffect, useRef, useState } from 'react'

import Arrow from '../../assets/icons/down.svg'

import { CollapsibleContainer } from './styles'

interface Props {
  title: string
  children: React.ReactNode
}

const Collapsible: React.FC<Props> = ({ title, children }: Props) => {
  const [open, setOpen] = useState(false)

  const innerContentRef = useRef<HTMLDivElement>(null)
  const [innerHeight, setInnerHeight] = useState(
    innerContentRef.current?.scrollHeight
  )

  useEffect(() => {
    if (!innerContentRef.current) return () => undefined

    setInnerHeight(innerContentRef.current.scrollHeight)

    const observer = new ResizeObserver(() => {
      setInnerHeight(innerContentRef.current?.scrollHeight)
    })

    observer.observe(innerContentRef.current)

    return () => {
      observer.disconnect()
    }
  }, [innerContentRef])

  return (
    <CollapsibleContainer>
      <button
        className="toggle"
        type="button"
        onClick={() => {
          setOpen(!open)
        }}
      >
        <span>{title}</span>
        <img
          src={Arrow}
          alt="arrow"
          className={`collapsible-arrow ${open ? 'open' : ''}`}
        />
      </button>
      <div
        className="content"
        style={{
          height: open ? `${innerHeight}px` : '0px',
          paddingBottom: open ? '20px' : '0px',
        }}
      >
        <div className="content-inner" ref={innerContentRef}>
          {children}
        </div>
      </div>
    </CollapsibleContainer>
  )
}

export default Collapsible
