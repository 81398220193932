import Viewer from '@r2u/viewer'
import React, { useState, useEffect, useMemo, CSSProperties } from 'react'

import '@r2u/javascript-ar-sdk'

import { useTranslation } from 'react-i18next'
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params'
import { dimensions, events } from '@r2u/analytics'
// eslint-disable-next-line import/no-unresolved
import { ViewerOptions } from '@r2u/javascript-ar-sdk/dist/methods/types'
import ARButton from '../../components/ARButton'
import {
  ContainerFull,
  ModelViewerContainer,
  FooterContainer,
  WaterMark,
} from './style'

import uIconGray from '../../assets/icons/u-logo-gray.svg'
import uIcon from '../../assets/icons/u-logo.svg'
import { getPdpUrl } from '../../services/sdkApi'
import { CustomerData, getCustomerData } from '../../services/customerService'

interface Params {
  customerId: string
  sku: string
}

interface ProductData {
  glb: string
  viewerOptions: ViewerOptions
}

const { R2U } = window

const getProductData = async ({
  customerId,
  sku,
}: Params): Promise<ProductData> => {
  await R2U.init({ customerId })

  const { glb, viewerOptions } = await R2U.sku.getData(sku)

  return { glb, viewerOptions }
}

const ModelViewerFullSDK: React.FC = () => {
  const [query] = useQueryParams({
    customerId: StringParam,
    sku: StringParam,
    hideARButton: StringParam,
    hideFooter: StringParam,
    showPdpButton: BooleanParam,
    pdpUrl: StringParam,
    pdpButtonText: StringParam,
    showLogo: BooleanParam,
    logoUrl: StringParam,
    pdpButtonPositionH: StringParam,
    pdpButtonPositionV: StringParam,
    animationSelector: BooleanParam,
  })

  const { t } = useTranslation()

  const [customerId] = useState(query.customerId)
  const [sku] = useState(query.sku)
  const [hideFooter] = useState(query.hideFooter)
  const [hideARButton] = useState(query.hideARButton)

  const [glbUrl, setGlbUrl] = useState(null)

  const [viewerOptions, setViewerOptions] = useState(null)

  const [active, setActive] = useState(false)

  const [uLogo, setULogo] = useState(uIconGray)
  const [customer, setCustomer] = useState<CustomerData>(null)
  const [pdpUrl, setPdpUrl] = useState(query.pdpUrl)
  const logoUrl = query.logoUrl || customer?.logoUrl

  const pdpPositionStyle = useMemo<CSSProperties>(() => {
    const pdpButtonPositionH = (query.pdpButtonPositionH || 'right') as
      | 'left'
      | 'center'
      | 'right'
    const pdpButtonPositionV = (query.pdpButtonPositionV || 'top') as
      | 'top'
      | 'bottom'

    let result: CSSProperties = {}
    switch (pdpButtonPositionH) {
      case 'left':
        result = { left: '10px' }
        break
      case 'center':
        result = { left: '50%', transform: 'translateX(-50%)' }
        break
      case 'right':
        result = { right: '10px' }
        break
      default:
        result = { right: '10px' }
        break
    }

    switch (pdpButtonPositionV) {
      case 'top':
        result.top = '10px'
        break
      case 'bottom':
        result.bottom = '10px'
        break
      default:
        result.top = '10px'
        break
    }
    return result
  }, [query])

  useEffect(() => {
    getProductData({ customerId, sku })
      .then((data) => {
        setGlbUrl(data.glb)
        setViewerOptions(data.viewerOptions)
      })
      .then(() => setActive(true))
      .then(() => window.top.postMessage('r2u-embed-success', '*'))
      .catch((e) => {
        window.top.postMessage('r2u-embed-error', '*')
      })
    if (!query.pdpUrl) getPdpUrl(customerId, sku).then(setPdpUrl)
    getCustomerData(customerId).then(setCustomer)
  }, [])

  const onClick = (): Promise<unknown> =>
    R2U.analytics.send({
      event: events.click,
      data: {
        [dimensions.placement]: 'ar_model_viewer',
        [dimensions.url]: `${
          document.referrer ? document.referrer : document.location.href
        }`,
      },
    })

  const componentDidMount = (): Promise<unknown> =>
    R2U.analytics.send({
      event: events.impression,
      data: {
        [dimensions.placement]: 'ar_model_viewer',
        [dimensions.url]: `${
          document.referrer ? document.referrer : document.location.href
        }`,
      },
      scope: 'page',
    })

  return active ? (
    <ContainerFull>
      {glbUrl ? (
        <ModelViewerContainer>
          <div className="model-viewer">
            <Viewer
              animationSelector={
                query.animationSelector !== false ? 'top' : 'hide'
              }
              glbSrc={glbUrl}
              {...viewerOptions}
              {...{ onClick, componentDidMount }}
            />
            {glbUrl && pdpUrl && query.showPdpButton && (
              <a
                className="pdp-button"
                href={pdpUrl}
                target="blank"
                style={pdpPositionStyle}
              >
                <img
                  src="/images/icons/external-link.png"
                  alt="External link icon"
                  className="icon"
                />
                <h3>{query.pdpButtonText || t('productPage.seeProduct')}</h3>
                {/* Exibe um imagem se exister uma e o usuário não tiver usado a query param showLogo=0. */}
                {query.showLogo !== false && logoUrl && (
                  <img
                    src={query.logoUrl || customer.logoUrl}
                    alt="Logo"
                    className="logo"
                  />
                )}

                {query.showLogo && !logoUrl && (
                  <div className="logo-placeholder">{customer.name}</div>
                )}
              </a>
            )}
          </div>
        </ModelViewerContainer>
      ) : null}
      <FooterContainer hide={hideFooter === 'true'}>
        {hideARButton === 'true' ? null : (
          <ARButton customerId={customerId} sku={sku} />
        )}
        {customerId === '89e502ed-f9ae-4147-82e1-f0868d2bb74b' ? null : (
          <WaterMark>
            <a href="https://r2u.io/" target="__blank">
              <img
                src={uLogo}
                alt="r2u icon u"
                onMouseEnter={() => setULogo(uIcon)}
                onMouseLeave={() => setULogo(uIconGray)}
              />
            </a>
          </WaterMark>
        )}
      </FooterContainer>
    </ContainerFull>
  ) : null
}

export default ModelViewerFullSDK
