import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from './styles'

const PoweredBy: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Container className="powered-by">
      <small>{t('poweredBy')}</small>
      <a href="https://r2u.io">
        <img alt="r2u" src="https://sdk.r2u.io/assets/r2u-logo.png" />
      </a>
    </Container>
  )
}

export default PoweredBy
