import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import i18next from 'i18next'

const i18nInstance = i18next.createInstance()

i18nInstance
  .use(ChainedBackend)
  .use(LanguageDetector)
  .init({
    debug: false,
    fallbackLng: 'en',
    supportedLngs: ['pt', 'en', 'es'],
    preload: ['pt', 'en', 'es'],
    interpolation: {
      escapeValue: false,
    },
    load: 'all',
    backend: {
      backends: [LocalStorageBackend, HttpBackend],
      backendOptions: [
        {
          prefix: 'i18next_res_r2u_viewer_',
          enabled: process.env.NODE_ENV !== 'development',
          expirationTime: 30 * 24 * 60 * 60 * 1000, // 30 days
          defaultVersion: `v1.0-${Date.now()}`,
        },
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
          allowMultiloading: true,
        },
      ],
    },
  })

export default i18nInstance
