import styled from 'styled-components'

export const OuterContainer = styled.div`
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-left: 100px;
`

export const Container = styled.div`
  width: 350px;
  height: 80%;
  padding: 20px 30px;
  z-index: 1;
  display: flex;
  flex-direction: column;

  border-radius: 10px;

  background-color: rgba(255, 255, 255, 0.35);
  backdrop-filter: blur(5px);
  box-shadow: 0px 0px 8px rgba(86, 86, 86, 0.25);

  .title {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 10px;
  }

  .customization-menu {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 2px;
  }
`

export const CollapsibleContainer = styled.div`
  width: 100%;
  box-shadow: 0px 0px 4px 1px rgba(86, 86, 86, 0.25);
  button.toggle {
    height: 48px;
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 1px solid #dee2e6;
    background-color: #ebf1f3;
    display: grid;
    grid-template-columns: 6fr 1fr;
    align-items: center;
    justify-items: start;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    & > *:first-child {
      margin-left: 20px;
    }
    .collapsible-arrow {
      width: 35px;
      height: 35px;
      transition: 0.2s linear;
      &.open {
        transform: rotate(180deg);
      }
    }
  }
  .content {
    padding: 0 10px;
    overflow: hidden;
    background-color: #fff;
    transition: 0.2s linear;
  }
`

export const Menu = styled.div`
  padding: 20px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  align-items: center;
  justify-items: center;
  label {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 120px;
    border-radius: 8px;
    box-shadow: -2px -2px 4px #ffffff, 2px 2px 4px rgba(86, 86, 86, 0.25);
    cursor: pointer;
  }
  input {
    display: none;
    &:checked + label {
      box-shadow: inset -2px -2px 4px #ffffff,
        inset 2px 2px 4px rgba(86, 86, 86, 0.25);
      background-color: rgba(86, 86, 86, 0.1);
    }
  }
`
