import React from 'react'
import { CustomView, isBrowser, isTablet, ViewProps } from 'react-device-detect'

type TBrowserViewProps = ViewProps

const BrowserView: React.FC<TBrowserViewProps> = ({
  children,
  ...rest
}: TBrowserViewProps) => {
  return (
    <CustomView condition={isBrowser && !isTablet} {...rest}>
      {children}
    </CustomView>
  )
}

export default BrowserView
