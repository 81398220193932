import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { MobileView } from 'react-device-detect'
import is from 'is_js'

import arIcon from '../../assets/images/ar-icon.png'

import '@r2u/javascript-ar-sdk'

import i18n from '../../config/i18n'
import './styles.css'
import BrowserView from '../../components/BrowserViewer'

interface Params {
  customerId: string
  productSku: string
}

interface AttachProps {
  customerId: string
  sku: string
  button: HTMLElement
}

const { R2U } = window

const attachButtonToSDK = async ({ customerId, sku, button }: AttachProps) => {
  const { language } = i18n

  await R2U.init({ customerId, language: language as 'pt' | 'en' | 'es' })

  await R2U.sku.isActive(sku)

  await R2U.ar.attach({
    sku,
    element: button,
    eventType: 'click',
    fallbackOptions: { fallback: 'viewer' },
    showInstructions: 'once',
  })

  document.addEventListener('visibilitychange', () => {
    if (!document.hidden && !is.ios()) window.close()
  })
}

const OpenAR: React.FC = () => {
  const { t } = useTranslation()

  const { customerId, productSku } = useParams<Params>()

  const [loading, setLoading] = useState(true)
  const [reopenAr, setReopenAr] = useState(false)

  const buttonRef = useCallback(
    (node: HTMLElement) => {
      if (customerId && productSku && node && loading) {
        attachButtonToSDK({
          customerId,
          sku: productSku,
          button: node,
        }).then(() => setLoading(false))
      }
      if (!loading && node) {
        node.click()
      }
    },
    [loading]
  )

  return (
    <main id="openar">
      {loading && (
        <div id="loader">
          <span>{t('openAR.loading')}</span>
        </div>
      )}

      <BrowserView>
        <h2>{t('openAR.info')}</h2>
      </BrowserView>

      <MobileView>
        {customerId && productSku ? (
          <button
            ref={buttonRef}
            type="button"
            onClick={() => setReopenAr(true)}
            disabled={loading}
          >
            {reopenAr && (
              <div className="ar-info">
                <img src={arIcon} alt="" />
                <span>{t('button')}</span>
              </div>
            )}
          </button>
        ) : (
          <div>
            <h2>{t('openAR.warning')}</h2>
          </div>
        )}
      </MobileView>
    </main>
  )
}

export default OpenAR
