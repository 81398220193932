import React, { useCallback, useState, useEffect } from 'react'
import { MobileView } from 'react-device-detect'
import QRCode from 'qrcode.react'

import { useTranslation } from 'react-i18next'

import arIcon from '../../assets/icons/view_in_ar.svg'
import closeIcon from '../../assets/icons/close.svg'
import r2uIcon from '../../assets/icons/r2u-logo.svg'

import i18n from '../../config/i18n'

import {
  ARButtonStyle,
  ARContainerButton,
  Container,
  PopupContainer,
  ContentPopup,
  QRCodeContainer,
  R2ULogo,
  PopupClose,
  PopupTitle,
} from './style'
import BrowserView from '../BrowserViewer'

interface Props {
  customerId: string
  sku: string
}

interface AttachProps {
  customerId: string
  sku: string
  button: HTMLElement
}

const { R2U } = window

const attachButtonToSDK = async ({ customerId, sku, button }: AttachProps) => {
  const { language } = i18n

  await R2U.init({ customerId, language: language as 'pt' | 'en' | 'es' })

  await R2U.ar.attach({
    sku,
    element: button,
    eventType: 'click',
    fallbackOptions: { fallback: 'viewer' },
    showInstructions: 'once',
  })
}

const ARButton: React.FC<Props> = ({ customerId, sku }: Props) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [active, setActive] = useState(false)
  const [popupAnimationType, setPopupAnimationType] = useState<
    'none' | 'open' | 'close'
  >('none')

  const viewerUrl = `${
    window.location.origin
  }/?customerId=${customerId}&sku=${sku}&originUrl=${encodeURIComponent(
    document.referrer !== '' ? document.referrer : window.location.href
  )}`

  const buttonRef = useCallback(
    (node: HTMLElement) => {
      if (customerId && sku && loading) {
        attachButtonToSDK({
          customerId,
          sku,
          button: node,
        }).then(() => setLoading(false))
      }
    },
    [loading]
  )

  const handleAnimationEnd = () => {
    if (popupAnimationType === 'close') setActive(false)
    setPopupAnimationType('none')
  }

  useEffect(() => {
    if (popupAnimationType === 'open') setActive(true)
  }, [popupAnimationType])

  return (
    <Container>
      <BrowserView className="browser-viewer">
        <ARContainerButton>
          <ARButtonStyle
            onClick={() =>
              active
                ? setPopupAnimationType('close')
                : setPopupAnimationType('open')
            }
            type="button"
            active={active}
          >
            <img src={arIcon} alt="ar icon" />
            <h3>{t('embed.button')}</h3>
          </ARButtonStyle>
        </ARContainerButton>
        {active && (
          <PopupContainer
            animationType={popupAnimationType}
            onAnimationEnd={handleAnimationEnd}
          >
            <PopupClose
              src={closeIcon}
              alt="close icon"
              onClick={() => {
                setPopupAnimationType('close')
              }}
            />
            <ContentPopup>
              <PopupTitle>
                <h4>{t('embed.popup.title')}</h4>
              </PopupTitle>
              <QRCodeContainer>
                <QRCode value={viewerUrl} />
              </QRCodeContainer>
              {customerId === '89e502ed-f9ae-4147-82e1-f0868d2bb74b' ? null : (
                <R2ULogo>
                  <a href="https://r2u.io/" target="__blank">
                    <h4>{t('embed.popup.powered')}</h4>
                    <img src={r2uIcon} alt="r2u icon" />
                  </a>
                </R2ULogo>
              )}
            </ContentPopup>
          </PopupContainer>
        )}
      </BrowserView>
      <MobileView className="mobile-viewer">
        <ARContainerButton>
          <ARButtonStyle ref={buttonRef} type="button" disabled={loading}>
            <img src={arIcon} alt="ar icon" />
            <h3>{t('embed.button')}</h3>
          </ARButtonStyle>
        </ARContainerButton>
      </MobileView>
    </Container>
  )
}

export default ARButton
