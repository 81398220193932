import styled from 'styled-components'

export const ContainerFull = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100% !important;
  height: 100vh !important;
  max-width: none !important;
`

export const PdpButton = styled.a`
  cursor: pointer;
  position: fixed;
  padding: 4px;
  background: #f9f9f9;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  gap: 8px;
  box-shadow: 0 0 8px rgba(86, 86, 86, 0.25);
  transition: 150ms ease-in-out;

  :hover {
    box-shadow: 0 0 18px rgba(86, 86, 86, 0.45);
  }

  h3 {
    font-family: Inter;
    font-size: 16px;
    line-height: 19px;
    color: #003e58;
    margin-right: 10px;
  }

  img.icon {
    width: 24px;
    margin-left: 10px;
  }

  img.logo {
    width: 100px;
    border-radius: 4px;
  }

  div.logo-placeholder {
    margin-left: 10px;
    background: linear-gradient(180deg, #eee 0%, #ddd 100%);
    font-size: 16px;
    width: 100px;
    border-radius: 4px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: black;
  }

  &:hover {
    box-shadow: 0 0 20px rgba(86, 86, 86, 0.25);
  }
`

export const ModelViewerContainer = styled.div`
  display: flex;
  flex: 5 1 !important;
  background: #f9f9f9 !important;
  overflow: hidden !important;

  .model-viewer {
    position: relative;
    width: 100% !important;
    height: 100% !important;
  }

  .pdp-button {
    cursor: pointer;
    position: fixed;
    padding: 4px;
    background: #f9f9f9;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    gap: 8px;
    box-shadow: 0 0 8px rgba(86, 86, 86, 0.25);
    transition: 150ms ease-in-out;

    :hover {
      box-shadow: 0 0 18px rgba(86, 86, 86, 0.45);
    }

    h3 {
      font-family: Inter;
      font-size: 16px;
      line-height: 19px;
      color: #003e58;
      margin-right: 10px;
    }

    img.icon {
      width: 24px;
      margin-left: 10px;
    }

    img.logo {
      width: 100px;
      border-radius: 4px;
    }

    div.logo-placeholder {
      margin-left: 10px;
      background: linear-gradient(180deg, #eee 0%, #ddd 100%);
      font-size: 16px;
      width: 100px;
      border-radius: 4px;
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      color: black;
    }

    &:hover {
      box-shadow: 0 0 20px rgba(86, 86, 86, 0.25);
    }
  }
`

interface FooterContainerProps {
  hide?: boolean
}

export const FooterContainer = styled.div<FooterContainerProps>`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  flex: 0.5 3 !important;
  background: transparent;
`

export const WaterMark = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;
  padding-right: 4px;
  padding-bottom: 4px;

  a {
    margin: 0;
    padding: 0;
    text-decoration: none;
    height: 40px;
  }

  img {
    height: 40px;
    margin: 0;
    padding: 0;
  }
`
