/* eslint-disable react/jsx-no-literals */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { StringParam, BooleanParam, useQueryParams } from 'use-query-params'
import { useTranslation } from 'react-i18next'
import { MobileView } from 'react-device-detect'
import QRCode from 'qrcode.react'

import type { ARProps } from '@r2u/react-ar-components'
import Viewer from '@r2u/viewer'
import * as ARComponents from '@r2u/react-ar-components'
import '@r2u/javascript-ar-sdk'
// eslint-disable-next-line import/no-unresolved
import { ViewerOptions } from '@r2u/javascript-ar-sdk/dist/methods/types'

import { dimensions, events } from '@r2u/analytics'
import type { ViewerRef } from '@r2u/viewer/dist/types'
import UploadDropzone from './UploadDropzone'
import { Container, Content, MobileContent } from './styles'

import americanas from '../../assets/icons/americanas.svg'
import arAmericanas from '../../assets/images/ar-icon-americanas.png'
import komfort from '../../assets/images/logo-komfort.png'
import arIcon from '../../assets/images/ar-icon.png'
import undo from './undo.png'

import PoweredBy from '../../components/PoweredBy'
import Spinner from '../../components/Spinner'

import i18n from '../../config/i18n'
import BrowserView from '../../components/BrowserViewer'
import CustomizationBar from '../../components/CustomizationBar'
import { getPdpUrl } from '../../services/sdkApi'
import { CustomerData, getCustomerData } from '../../services/customerService'

type SceneTree = ReturnType<ViewerRef['getSceneTree']>

const { R2U } = window

const isIOS = (): boolean => /iPhone|iPad|iPod/i.test(navigator.userAgent)
const isAndroid = (): boolean =>
  /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

type Placement = 'floor' | 'wall'
type ShowInstructions = 'once' | 'always' | 'never'

interface ConfigureProps {
  customerId: string
  sku: string
  refreshCache?: boolean
  setName: (name: string) => void
  setGlb: (glb: string) => void
  setViewerOptions: (viewerOptions: ViewerOptions) => void
}

interface AttachProps {
  sku: string
  button: HTMLElement
  showInstructions: ShowInstructions
}

type ARFunction = (props: ARProps) => void

const configureSDK = async ({
  customerId,
  sku,
  refreshCache,
  setName,
  setGlb,
  setViewerOptions,
}: ConfigureProps) => {
  const { language } = i18n

  await R2U.init({
    customerId,
    language: language as 'pt' | 'en' | 'es',
    refreshCache,
  })

  await R2U.sku.isActive(sku)
  const { name, glb, viewerOptions } = await R2U.sku.getData(sku)

  setName(name)
  setGlb(glb)
  setViewerOptions(viewerOptions || null)
}

const attachButton = async ({ sku, button, showInstructions }: AttachProps) => {
  await R2U.ar.attach({
    sku,
    element: button,
    eventType: 'click',
    fallbackOptions: { fallback: 'viewer' },
    showInstructions,
  })
}

let activateAR: ARFunction = () => undefined

const getActivateAR = async () => {
  const { activateAR: arFunction } = await ARComponents
  activateAR = arFunction
}

const ProductPage: React.FC = () => {
  const { t, ready } = useTranslation()

  const [query, setQuery] = useQueryParams({
    glb: StringParam,
    usdz: StringParam,
    customizationUri: StringParam,
    name: StringParam,
    autoRotate: BooleanParam,
    customerId: StringParam,
    sku: StringParam,
    placement: StringParam,
    resize: BooleanParam,
    autoOpen: BooleanParam,
    showInstructions: StringParam,
    refreshCache: BooleanParam,
    originUrl: StringParam,
    showLogo: BooleanParam,
    logoUrl: StringParam,
    showPdpButton: BooleanParam,
    pdpUrl: StringParam,
    pdpButtonText: StringParam,
    animationSelector: BooleanParam,
  })

  const [hasUploaded, setHasUploaded] = useState(false)

  const customizationGlb =
    query.customizationUri &&
    `https://storage.googleapis.com/${query.customizationUri}.glb`
  const customizationUsdz =
    query.customizationUri &&
    `https://storage.googleapis.com/${query.customizationUri}.usdz`

  const [glb, setGlb] = useState(query.glb || customizationGlb)
  const [usdz] = useState(query.usdz || customizationUsdz)
  const [name, setName] = useState(query.name)
  const [placement = 'floor'] = useState(query.placement as Placement)
  const [resize = false] = useState(query.resize)
  const [autoOpen = false] = useState(query.autoOpen)
  const [originUrl] = useState(query.originUrl)
  const [showInstructions = 'always'] = useState(
    query.showInstructions as ShowInstructions
  )
  const [viewerOptions, setViewerOptions] = useState(null)

  const viewerRef = useRef<ViewerRef>(null)
  const [viewerLoaded, setViewerLoaded] = useState(false)
  const [sceneTree, setSceneTree] = useState<SceneTree>(null)

  useEffect(() => {
    if (!viewerRef.current || !viewerLoaded) return

    setSceneTree(viewerRef.current.getSceneTree())
  }, [viewerLoaded])

  const hasCustomization = useMemo(
    () =>
      sceneTree?.components.length ||
      sceneTree?.slotsMaterials.reduce(
        (acc, slot) => acc || slot.materials.length,
        0
      ),
    [sceneTree]
  )

  const scale = resize ? 'resizable' : 'fixed'

  useEffect(() => {
    if (ready && !name) {
      setName(t('productPage.name'))
    }
  }, [ready])

  const [customerId] = useState(query.customerId)
  const [sku] = useState(query.sku)
  const [refreshCache] = useState(query.refreshCache)
  const sdkMode = !!(customerId && sku)

  const [initializedSDK, setInitializedSDK] = useState(false)
  const [loadingAR, setLoadingAR] = useState(true)
  const [fallback, setFallback] = useState(false)
  const [loadedARComponents, setLoadedARComponents] = useState(false)
  const [pdpUrl, setPdpUrl] = useState<string>(query.pdpUrl)
  const [customer, setCustomer] = useState<CustomerData>(null)
  const logoUrl = query.logoUrl || customer?.logoUrl

  useEffect(() => {
    if (sdkMode) {
      if (!query.pdpUrl) getPdpUrl(customerId, sku).then(setPdpUrl)
      getCustomerData(customerId).then(setCustomer)
      configureSDK({
        customerId,
        sku,
        refreshCache,
        setName,
        setGlb,
        setViewerOptions,
      }).then(() => setInitializedSDK(true))
    }
    getActivateAR().then(() => setLoadedARComponents(true))
  }, [])

  const canOpenAR = (isAndroid() && glb) || (isIOS() && usdz)

  const warnARListener = () => {
    if (isAndroid() && !glb) {
      alert(t('productPage.alert.glb'))
    }
    if (isIOS() && !usdz) {
      alert(t('productPage.alert.usdz'))
    }
  }

  const activateARListener = () =>
    activateAR({ glb, usdz, placement, scale, mode: 'ar_preferred' })
  const fallbackListener = () => alert(t('productPage.alert.fallback'))
  const hashChangeListener = () => {
    if (window.location.hash === '#no-ar-fallback') {
      setFallback(true)
    }
  }

  const buttonRef = useCallback(
    (node: HTMLElement) => {
      if (node) {
        if (sdkMode) {
          // Wait for SDK initialization to attach AR to the button
          if (initializedSDK && loadingAR) {
            attachButton({ sku, button: node, showInstructions }).then(() =>
              setLoadingAR(false)
            )
          }

          if (autoOpen) {
            node.click()
          }
        } else {
          // Wait for async loading of arComponents before adding listeners
          if (loadingAR) {
            // Check if GLB is present on Android or USDZ on iOS
            if (canOpenAR) {
              node.addEventListener('click', activateARListener)
              window.addEventListener('hashchange', hashChangeListener)
              setLoadingAR(false)
            } else {
              node.addEventListener('click', warnARListener)
              node.click()
              setLoadingAR(false)
            }
          }

          // Check for a fallback hashChange, remove AR,
          // attach a listener to repeat fallback message and instantly call it
          if (fallback) {
            node.removeEventListener('click', activateARListener)
            node.addEventListener('click', fallbackListener)
            node.click()
          }
        }
      }
    },
    [initializedSDK, loadedARComponents, loadingAR, fallback]
  )

  const onClick = (): Promise<unknown> =>
    R2U.analytics.send({
      event: events.click,
      data: {
        [dimensions.placement]: 'ar_model_viewer',
        [dimensions.url]: `${originUrl || window.location.href}`,
      },
    })

  const componentDidMount = (): Promise<unknown> =>
    R2U.analytics.send({
      event: events.impression,
      data: {
        [dimensions.placement]: 'ar_model_viewer',
        [dimensions.url]: `${originUrl || window.location.href}`,
      },
      scope: 'page',
    })

  return (
    <>
      <MobileView>
        <MobileContent>
          {customerId === '701ba5cb-152f-4724-9a5f-bb5b1c42d81c' ? (
            <div className="americanas">
              <div className="header">
                <img src={americanas} alt="" />
              </div>
              <div className="ar-container">
                <img src={arAmericanas} alt="" />
                <button ref={buttonRef} type="button" className="ar-button">
                  <span>começar experiência AR</span>
                </button>
              </div>
              <div className="footer">
                <PoweredBy />
              </div>
            </div>
          ) : (
            <button ref={buttonRef} type="button" disabled={loadingAR}>
              {loadingAR ? (
                <div className="loading">
                  <Spinner />
                </div>
              ) : customerId === 'c46943ac-ac1a-4170-8f52-c8705b963538' ? (
                <div className="ar-info">
                  <img
                    src={arIcon}
                    alt=""
                    style={{
                      filter:
                        'brightness(0) saturate(100%) invert(43%) sepia(97%) saturate(1505%) hue-rotate(357deg) brightness(103%) contrast(94%)',
                    }}
                  />
                  <span>{t('button')}</span>
                </div>
              ) : (
                <div className="ar-info">
                  <img src={arIcon} alt="" />
                  <span>{t('button')}</span>
                </div>
              )}
              {customerId ===
              '89e502ed-f9ae-4147-82e1-f0868d2bb74b' ? null : customerId ===
                'c46943ac-ac1a-4170-8f52-c8705b963538' ? (
                <a
                  href="https://komforthouse.com.br/"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '30px',
                    alignSelf: 'center',
                  }}
                >
                  <img
                    alt="komfort"
                    src={komfort}
                    style={{ height: '70px', width: 'auto' }}
                  />
                </a>
              ) : (
                <PoweredBy />
              )}
            </button>
          )}
        </MobileContent>
      </MobileView>
      <BrowserView>
        <Container>
          {sdkMode && !initializedSDK ? (
            <div className="loading">
              <Spinner />
            </div>
          ) : (
            <Content glb={!!glb}>
              {hasCustomization ? (
                <CustomizationBar
                  sceneTree={sceneTree}
                  setSceneTree={setSceneTree}
                  viewerRef={viewerRef}
                />
              ) : (
                <div className="left-container">
                  <div className="left">
                    {/* Exibe a logo caso exista alguma e o usuário não tenha especificado para não mostrar. */}
                    {sdkMode && logoUrl && query.showLogo !== false && (
                      <div className="logo-container">
                        <img
                          src={logoUrl}
                          alt=""
                          title={customer.name}
                          className="logo"
                        />
                      </div>
                    )}
                    {/* Exibe uma logo automática caso o usuário tenha pedido explicitamente
                      para mostrar, mas não haja uma imagem de logo. */}
                    {sdkMode && !logoUrl && query.showLogo && (
                      <div className="logo-container">
                        <div className="logo-placeholder">{customer.name}</div>
                      </div>
                    )}
                    {/* Caso o usuário não tenha especificado se quer ou não exibir, só será exibida uma logo se houver. */}
                    <div className="scroll">
                      <div className="top" style={{ marginBottom: '20px' }}>
                        <h1>{name}</h1>
                      </div>
                      {sdkMode && pdpUrl && query.showPdpButton !== false && (
                        <a href={pdpUrl} target="blank" className="pdpurl">
                          <img
                            src="images/icons/external-link.png"
                            alt="External link icon"
                          />
                          <div>
                            {query.pdpButtonText || t('productPage.seeProduct')}
                          </div>
                        </a>
                      )}
                      <div className="bottom" style={{ marginTop: '20px' }}>
                        <div className="open-ar">
                          <h2>{t('productPage.info')}</h2>
                          <QRCode value={window.location.href} />
                        </div>
                      </div>
                      {customerId ===
                      '89e502ed-f9ae-4147-82e1-f0868d2bb74b' ? null : customerId ===
                        'c46943ac-ac1a-4170-8f52-c8705b963538' ? (
                        <a
                          href="https://komforthouse.com.br/"
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '30px',
                          }}
                        >
                          <img
                            alt="komfort"
                            src={komfort}
                            style={{ height: '70px', width: 'auto' }}
                          />
                        </a>
                      ) : (
                        <PoweredBy />
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="right">
                {glb ? (
                  <div className="model-viewer-container">
                    <Viewer
                      ref={viewerRef}
                      onLoad={() => {
                        setViewerLoaded(true)
                      }}
                      animationSelector={
                        query.animationSelector !== false ? 'bottom' : 'hide'
                      }
                      glbSrc={glb}
                      {...(sdkMode
                        ? { onClick, componentDidMount, ...viewerOptions }
                        : {})}
                    >
                      {hasUploaded && (
                        <button
                          type="button"
                          className="undo"
                          onClick={() => {
                            setQuery({ glb: '' })
                            setGlb('')
                          }}
                        >
                          <img alt="undo" src={undo} />
                        </button>
                      )}
                    </Viewer>
                  </div>
                ) : (
                  <div className="upload-zone-container">
                    <div className="upload-zone">
                      <UploadDropzone
                        onComplete={(glbUrl) => {
                          setQuery({ glb: glbUrl })
                          setGlb(glbUrl)
                          setHasUploaded(true)
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Content>
          )}
        </Container>
      </BrowserView>
    </>
  )
}

export default ProductPage
