import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  color: #000000;

  font-family: 'futura-pt', sans-serif;
  text-align: center;

  border-radius: 3px;
  border: 1px solid #dadce0;

  @media (max-width: 641px) {
    display: none;
  }

  .progress {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
`
