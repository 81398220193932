import React, { useEffect, useState } from 'react'
import { Line } from 'rc-progress'

import { useTranslation } from 'react-i18next'
import upload from '../../services/upload'
import {
  getConversionUrl,
  getJobStatus,
  getMultipleConversions,
} from '../../services/customizerApi'
import { Container } from './styles'

import blendIcon from '../../assets/icons/blend.svg'
import skpIcon from '../../assets/icons/skp.svg'
import gltfIcon from '../../assets/icons/gltf.svg'
import usdzIcon from '../../assets/icons/usdz.svg'

interface Props {
  file: File
}

type Status = 'waiting' | 'success' | 'failed' | 'processing'

interface Conversion {
  jobId: string
  status: Status
  outputPath: string
}

const FileInfo: React.FC<Props> = ({ file }: Props) => {
  const { t } = useTranslation()

  const [uploadProgress, setUploadProgress] = useState(0)

  const [uploadURL, setUploadURL] = useState('')
  const [viewerURL, setViewerURL] = useState('')

  const [conversions, setConversions] = useState<Conversion[]>([])

  const [success, setSuccess] = useState(false)
  const [failed, setFailed] = useState(false)

  const [fileName, fileType] = file.name.split('.')

  useEffect(() => {
    if (file) {
      upload({
        file,
        onProgress: ({ loaded, total }) => {
          setUploadProgress(total !== 0 ? (100 * loaded) / total : 100)
        },
        onComplete: ({ url }) => {
          setUploadProgress(100)
          setUploadURL(url)
        },
      })
    }
  }, [])

  useEffect(() => {
    if (uploadURL.endsWith('.skp')) {
      getMultipleConversions(uploadURL).then((response) => {
        const [glb] = response
          .filter((element) => element.outputPath.endsWith('.glb'))
          .map((element) => element.outputPath)

        const [usdz] = response
          .filter((element) => element.outputPath.endsWith('.usdz'))
          .map((element) => element.outputPath)

        setViewerURL(
          `https://viewer.r2u.io/?glb=${encodeURIComponent(
            glb
          )}&usdz=${encodeURIComponent(usdz)}`
        )
        setConversions(response)
      })
    } else if (uploadURL.endsWith('.glb')) {
      getConversionUrl(uploadURL).then(({ usdz, jobId }) => {
        setViewerURL(
          `https://viewer.r2u.io/?glb=${encodeURIComponent(
            uploadURL
          )}&usdz=${encodeURIComponent(usdz)}`
        )
        setConversions([{ jobId, outputPath: usdz, status: 'waiting' }])
      })
    }
  }, [uploadURL])

  useEffect(() => {
    const allCheck = conversions.every(({ status }) => status === 'success')
    const oneFailed = conversions.some(({ status }) => status === 'failed')

    setSuccess(allCheck)
    setFailed(oneFailed)

    if (conversions && !allCheck && !oneFailed) {
      Promise.all(
        conversions.map(async (conversion) => ({
          ...conversion,
          status: await getJobStatus(conversion.jobId),
        }))
      ).then(setConversions)
    }
  }, [conversions])

  const getIcon = (type: string) => {
    switch (type) {
      case 'blend':
        return blendIcon
      case 'skp':
        return skpIcon
      case 'glb':
        return gltfIcon
      case 'usdz':
        return usdzIcon
      default:
        return ''
    }
  }

  return (
    <Container className="file-info">
      <div className="info">
        <img className="icon" src={getIcon(fileType)} alt="" />
        <span className="type">{fileType}</span>
        <span className="name">{fileName}</span>
      </div>
      <div className="loading">
        <h3>
          {uploadProgress < 100
            ? t('file.loading.uploading')
            : success || failed
            ? t('file.loading.complete')
            : t('file.loading.converting')}
        </h3>
        <Line
          percent={uploadProgress}
          strokeColor={uploadProgress === 100 ? '#a5cc6b' : '#4886b5'}
          className="progress-bar"
        />
      </div>
      <div className="bottom">
        <div className="conversions">
          {conversions.map(({ jobId, outputPath, status }) => {
            const extension = outputPath.split('.').pop()
            return (
              <div key={jobId} className="conversion">
                <img className="icon" src={getIcon(extension)} alt="" />
                {status === 'success' ? (
                  <span className="download">
                    <a href={outputPath} download>
                      {t('file.download', {
                        extension,
                      })}
                    </a>
                  </span>
                ) : (
                  <span className="download disabled">
                    {t('file.download', { extension })}
                  </span>
                )}
                <h3 className={status}>{status}</h3>
              </div>
            )
          })}
        </div>
        {viewerURL && (
          <a
            href={viewerURL}
            className="viewer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="download">{t('file.viewer.info')}</span>
            <img src="https://sdk.r2u.io/assets/r2u-logo.png" alt="" />
            {success ? null : failed ? (
              <span className="failed">{t('file.viewer.failed')}</span>
            ) : (
              <span className="wait">{t('file.viewer.wait')}</span>
            )}
          </a>
        )}
      </div>
    </Container>
  )
}

export default FileInfo
