import styled from 'styled-components'

export const Container = styled.div`
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  color: #272727;

  span {
    margin: 5px;
  }

  .type {
    text-transform: uppercase;
  }

  .loading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    gap: 10px;

    .progress-bar {
      height: 10px;
      width: 75%;
      border-radius: 10px;
    }
  }

  .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    img {
      width: 40px;
      height: 40px;
      margin: 5px;
    }

    .name {
      width: 50%;
      word-wrap: no-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 1.25rem;
    }
  }

  .bottom {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;

    .conversion {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      margin: 5px;
      height: 30px;

      img {
        width: 25px;
      }

      a {
        text-decoration: none;
        color: #4886b5;
      }

      span {
        margin-left: 20px;
      }

      .download {
        &.disabled {
          color: darkgray;
        }
      }

      h3 {
        margin: 0;
        margin-left: auto;
      }

      .waiting {
        color: #ffbc42;
      }
      .success {
        color: #a5cc6b;
      }
      .failed {
        color: #ff8484;
      }
    }

    .viewer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      text-decoration: none;
      color: #4886b5;

      img {
        height: 100px;
      }

      .wait,
      .failed {
        color: #ff8484;
        max-width: 50%;
      }
    }
  }
`
