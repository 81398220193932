import React, { Suspense } from 'react'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { QueryParamProvider } from 'use-query-params'
import { I18nextProvider } from 'react-i18next'
import ModelViewerFull from './pages/ModelViewerFull'
import ModelViewerFullSDK from './pages/ModelViewerFullSDK'
import ProductPage from './pages/ProductPage'
import OpenAR from './pages/OpenAR'
import ConvertPage from './pages/Convert'
import Spinner from './components/Spinner'

import i18n from './config/i18n'

import Fonts from './fonts'

const App: React.FC = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Fonts />
      <Suspense fallback={<Spinner />}>
        <Router>
          <QueryParamProvider ReactRouterRoute={Route}>
            <Switch>
              <Route
                path="/openar/:customerId/:productSku"
                component={OpenAR}
              />
              <Route path="/3d/:glb" component={ModelViewerFull} />
              <Route path="/embed" component={ModelViewerFullSDK} />
              <Route path="/convert" component={ConvertPage} />
              <Route path="/" component={ProductPage} />
            </Switch>
          </QueryParamProvider>
        </Router>
      </Suspense>
    </I18nextProvider>
  )
}

export default App
