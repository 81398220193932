import axios from 'axios'

interface UploadRequest {
  filename: string
  customerSlug: string
}

interface UploadResponse {
  fields: {
    bucket: string
    key: string
  }
  url: string
}

interface ConvertResponse {
  usdz: string
  jobId: string
}

type Status = 'waiting' | 'success' | 'failed' | 'processing'

interface MultipleConvertResponse {
  jobId: string
  status: Status
  outputPath: string
}

const customizerApi = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:4000/local'
      : 'https://customizer-api.r2u.io/production',
})

export const getUploadURL = async (
  body: UploadRequest
): Promise<UploadResponse> => {
  const { data } = await customizerApi.post<UploadResponse>('upload', body)
  return data
}

export const getConversionUrl = async (
  url: string
): Promise<ConvertResponse> => {
  const { data } = await customizerApi.post<ConvertResponse>('convert', { url })
  return data
}

export const getMultipleConversions = async (
  url: string
): Promise<MultipleConvertResponse[]> => {
  const { data } = await customizerApi.post<MultipleConvertResponse[]>(
    'convert',
    { url }
  )
  return data
}

export const getJobStatus = async (jobId: string): Promise<Status> => {
  const { data: status } = await customizerApi.get<Status>('job-status', {
    params: { jobId },
  })
  return status
}

export default customizerApi
