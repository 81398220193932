import { createGlobalStyle } from 'styled-components'

import Roboto from './Roboto.ttf'

export default createGlobalStyle`
  @font-face {
    font-family: 'Roboto';
    src: url(${Roboto}) format('truetype');
  }
`
