import React from 'react'
import { Container } from './styles'

// https://glennmccomb.com/articles/building-a-pure-css-animated-svg-spinner/
const Spinner: React.FC = () => {
  return (
    <Container>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="45" />
      </svg>
    </Container>
  )
}

export default Spinner
