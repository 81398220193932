import styled, { css } from 'styled-components'

export const MobileContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  overflow: auto;

  button {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    outline: none;
    width: 100%;
    height: 100%;
    border: none;
    cursor: pointer;
    text-decoration: none;
    appearance: none;
    padding: 25px;
    margin: 0;
    background: none;

    .ar-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      width: 100%;
      height: 100%;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 1px;

      & > img {
        margin: 20px 0;
        width: 40px;
        animation: pulseSize 3s infinite alternate-reverse;
      }
    }

    .loading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  .americanas {
    height: 100vh;
    background-color: #f1f1f1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f80032;
      width: 100%;
      border-radius: 0px 0px 16px 16px;
      padding: 28px 0px;
    }

    .ar-container {
      background-color: #ffffff;
      padding: 24px 32px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
        font-family: 'Roboto', sans-serif;
        background-color: #f80032;
        border-radius: 8px;
        margin-top: 14px;
        padding: 10px;
        font-size: 14px;
        color: #ffffff;
        font-weight: 700;
      }
    }

    .footer {
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      small {
        color: #2097d5;
        font-size: 12px;
      }

      img {
        width: 50px;
        height: auto;
      }
    }
  }
`

export const Container = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;

  color: #000000;

  font-family: 'futura-pt', sans-serif;
`

interface ContentProps {
  glb: boolean
}

export const Content = styled.div<ContentProps>`
  display: flex;
  height: 100%;
  justify-content: space-between;

  @media (max-width: 641px) {
    height: 100%;
  }

  .left-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 100px;

    .left {
      z-index: 1;
      width: 380px;
      max-height: 80%;
      position: relative;
      margin-top: 60px;
      margin-bottom: 20px;

      ${({ glb }) =>
        glb
          ? css`
              box-shadow: 0 0 8px rgba(86, 86, 86, 0.25);
              backdrop-filter: blur(5px);
              background-color: rgba(255, 255, 255, 0.35);
            `
          : null}

      border-radius: 10px;
      padding: 20px;

      @media (max-width: 641px) {
        display: none;
      }

      .logo-container {
        margin-top: -70px;
        text-align: center;

        img {
          display: inline-block;
          width: 120px;
          height: 120px;
          border-radius: 100%;
          background: linear-gradient(180deg, #eee 0%, #ddd 100%);
          object-fit: scale-down;
        }

        @media (max-height: 500px) {
          margin-top: -40px;

          img {
            width: 80px;
            height: 80px;
          }
        }

        .logo-placeholder {
          position: relative;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          text-overflow: ellipsis;
          background: linear-gradient(180deg, #eee 0%, #ddd 100%);
          width: 120px;
          height: 120px;
          border-radius: 100%;
          font-size: 20px;
          line-height: 24px;
        }
      }

      .scroll {
        display: flex;
        flex-direction: column;
        max-height: 65vh;
        overflow-y: auto;
        overflow-x: visible;
        padding: 20px;

        h1 {
          font-size: 24px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: normal;
          text-align: center;
        }

        .top {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }

        .pdpurl {
          padding: 8px 0;
          border-radius: 10px;
          color: black;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          font-size: 20px;
          gap: 5px;
          box-shadow: 0 0 8px rgba(86, 86, 86, 0.25);
          transition: 150ms ease-in-out;
          background: #ffffffa0;

          :hover {
            box-shadow: 0 0 8px rgba(86, 86, 86, 0.45);
            background: #ffffffe0;
          }

          img {
            width: 1.1em;
          }
        }

        .bottom {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .open-ar {
            display: flex;
            flex-direction: column;
            align-items: center;

            h2 {
              font-size: 18px;
              font-weight: 300;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.28;
              letter-spacing: normal;
              text-align: center;
              color: #000000;
            }
          }
        }
      }
    }
  }
  .right {
    width: 67%;
    max-width: 90vh;
    position: relative;

    @media (max-width: 641px) {
      width: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    .model-viewer-container {
      height: 100% !important;
      position: absolute;
      width: 150vw;
      right: -50vw;

      .undo {
        position: fixed;
        top: 0;
        right: 0;
        margin: 20px;

        padding: 0;
        border: none;
        font: inherit;
        color: inherit;
        background-color: transparent;

        img {
          width: 32px;
        }
      }
    }

    .upload-zone-container {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .upload-zone {
        height: 90%;
        width: 90%;
      }
    }
  }
`
