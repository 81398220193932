import axios from 'axios'

const api = axios.create({
  baseURL: 'https://api.3d.r2u.io/v1/customers',
})

export interface CustomerData {
  logoUrl: string
  name: string
}

export async function getCustomerData(
  customerId: string
): Promise<CustomerData> {
  const response = await api.get(customerId)
  return response.data as CustomerData
}
