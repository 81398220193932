import axios from 'axios'

const api = axios.create({
  baseURL: 'https://api.r2u.io',
})

export async function getPdpUrl(
  customerId: string,
  sku: string
): Promise<string> {
  const {
    data: [product],
  } = (await api.get(
    `customers/${customerId}/products/assets?product.sku=${sku}&asset.types=GLB%2CUSDZ&product_review.status=RUNNING`
  )) || {
    data: [
      {
        pdpUrl: null,
      },
    ],
  }

  return product.pdpUrl
}
