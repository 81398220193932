import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 30px;

  &:link,
  &:visited,
  &:active,
  &:hover {
    color: inherit;
    text-decoration: none;
  }

  small {
    margin-bottom: 10px;
    margin-top: 10px;

    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
  }

  img {
    width: 70px;
    height: 36px;
    object-fit: contain;
  }
`
