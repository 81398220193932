import Viewer from '@r2u/viewer'
import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import './style.css'

interface Params {
  glb?: string
}

const ModelViewerFull: React.FC = () => {
  const [glbUrl, setGlbUrl] = useState(null)

  const { glb } = useParams<Params>()

  useEffect(() => {
    setGlbUrl(glb ? decodeURIComponent(glb) : null)
  }, [glb])

  return (
    <div className="container-full">
      {glbUrl ? (
        <div className="model-viewer-container">
          <Viewer glbSrc={glbUrl} exposure={0.8} hdri="neutral" />
        </div>
      ) : null}
    </div>
  )
}

export default ModelViewerFull
