import styled, { keyframes, css } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  .browser-viewer,
  .mobile-viewer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 4px;
    padding-bottom: 4px;

    @media (max-width: 350px) {
      justify-content: flex-start;
      align-items: flex-start;
      min-width: 90vw;
    }
  }

  @media (min-width: 700px) {
    justify-content: center;
  }
`

export const ARContainerButton = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: fit-content;
  padding: 4px;
`

export const ARButtonStyle = styled.button.attrs((props) => ({
  active: props.active || false,
}))`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;
  box-shadow: ${(props) =>
    props.active
      ? `inset -2px -2px 4px #ffffff,
  inset 2px 2px 4px rgba(86, 86, 86, 0.25), inset 0 0 0 1px #ffffff;`
      : `-2px -2px 4px #ffffff30, 2px 2px 4px rgba(86, 86, 86, 0.25),
  inset 0 0 0 1px #ffffff;`}
  border-radius: 8px;
  border: none;
  padding: 8px;
  margin: none;

  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    filter: brightness(0) saturate(100%) invert(19%) sepia(22%) saturate(3489%)
      hue-rotate(165deg) brightness(96%) contrast(104%);
  }

  h3 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin: 0;
    color: #003e58;
  }

  &:hover {
    box-shadow: inset -2px -2px 4px #ffffff,
      inset 2px 2px 4px rgba(86, 86, 86, 0.25), inset 0 0 0 1px #ffffff;
  }

  @media(max-width: 350px) {
    img {
      margin: 0;
    }

    h3 {
      display: none;
    }
  }
`

export const growKeyframe = keyframes`
  from {
    transform: scaleY(0);
    transform-origin: bottom;
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    transform-origin: bottom
  }
`

export const PopupContainer = styled.div.attrs((props) => ({
  popupAnimation: props.animationType,
}))`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
  background: #f9f9f9;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  bottom: 110%;
  ${({ popupAnimation }) =>
    popupAnimation === 'none'
      ? null
      : css`
          animation: ${growKeyframe} 0.2s ease-in forwards
            ${popupAnimation === 'open' ? '' : 'reverse'};
        `}
`

export const PopupClose = styled.img`
  position: absolute;
  width: 16px;
  height: 16px;
  right: 1.5%;
  top: 1.5%;
  border-radius: 20%;
  filter: invert(51%) sepia(0%) saturate(1459%) hue-rotate(163deg)
    brightness(94%) contrast(90%);

  &:hover {
    filter: none;
    cursor: pointer;
  }
`

export const ContentPopup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px 14px;
  row-gap: 24px;

  @media (max-height: 349px) {
    row-gap: 8px;
  }
`

export const PopupTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  flex: 1 1 !important;
  order: 0;

  h4 {
    font-family: Inter;
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-align: center;
    color: #000;
    margin: 0;
  }

  @media (max-width: 350px) {
    line-height: 15px;
  }

  @media (max-height: 319px) {
    h4 {
      font-size: 14px;
    }
  }
`

export const QRCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex: 5 1 !important;
  order: 1;

  canvas {
    width: 128px !important;
    height: 128px !important;
  }

  @media (max-height: 319px) {
    canvas {
      width: 100px !important;
      height: 100px !important;
    }
  }
`

export const R2ULogo = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  flex: 1 1 !important;
  order: 2;
  padding: 0;

  a {
    display: flex;
    flex-flow: row nowrap;
    margin: 0;
    padding: 0;
    text-decoration: none;
  }

  h4 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    margin: 0 4px;
    color: #7a7a7a;
  }

  img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 21px;
    margin: 0;
    cursor: pointer;
  }
`
