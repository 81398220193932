/* eslint-disable react/jsx-no-literals */
import React from 'react'

import SlotsModelsMenu from './SlotsModelsMenu'
import { Container, OuterContainer } from './styles'
import type { SceneTree, ViewerRef } from './types'
import PoweredBy from '../PoweredBy'

interface Props {
  sceneTree: SceneTree
  setSceneTree: (sceneTree: SceneTree) => void
  viewerRef: ViewerRef
}

const CustomizationBar: React.FC<Props> = ({
  sceneTree,
  setSceneTree,
  viewerRef,
}: Props) => {
  return (
    <OuterContainer>
      <Container>
        <div className="title">Model customization</div>
        <div className="customization-menu">
          <SlotsModelsMenu
            viewerRef={viewerRef}
            sceneTree={sceneTree}
            onChange={setSceneTree}
          />
        </div>
        <PoweredBy />
      </Container>
    </OuterContainer>
  )
}

export default CustomizationBar
