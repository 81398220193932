import React, { ReactNode, useCallback, useEffect } from 'react'

import { useDropzone } from 'react-dropzone'
import { Container } from './styles'

interface Props {
  accept: string | string[]
  onAcceptedFiles: (files: File[]) => void
  setIsDragActive: (isDragActive: boolean) => void
  children?: ReactNode
}

const Dropzone: React.FC<Props> = ({
  accept,
  onAcceptedFiles,
  setIsDragActive,
  children,
}: Props) => {
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      onAcceptedFiles(acceptedFiles)
    },
    [onAcceptedFiles]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  })

  useEffect(() => {
    setIsDragActive(isDragActive)
  }, [isDragActive])

  return (
    <Container {...getRootProps()} className="dropzone">
      <input {...getInputProps()} />
      {children}
    </Container>
  )
}

export default Dropzone
