import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Container } from './styles'

import Dropzone from '../../components/Dropzone'
import FileInfo from '../../components/FileInfo'

const ConvertPage: React.FC = () => {
  const { t } = useTranslation()

  const accept = ['.glb', '.skp']

  const [isDragActive, setIsDragActive] = useState(false)
  const [files, setFiles] = useState<File[]>([])

  const appendFiles = (newFiles: File[]) =>
    setFiles([
      ...files,
      ...newFiles.filter(
        (f) => !files.map((file) => file.name).includes(f.name)
      ),
    ])

  const dropzoneProps = {
    accept,
    onAcceptedFiles: appendFiles,
    setIsDragActive,
  }

  return (
    <Container>
      <div className="left">
        <Dropzone {...dropzoneProps}>
          {isDragActive ? (
            <div className="background">{t('dropzone.drop')}</div>
          ) : (
            <div>{t('dropzone.info')}</div>
          )}
        </Dropzone>
      </div>
      <div className="right">
        {files.map((file) => (
          <FileInfo file={file} key={file.name} />
        ))}
      </div>
    </Container>
  )
}

export default ConvertPage
