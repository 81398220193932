import React, { useEffect, useState } from 'react'
import { Line } from 'rc-progress'
import { useTranslation } from 'react-i18next'

import Dropzone from '../../components/Dropzone'
import upload from '../../services/upload'

interface Props {
  onComplete: (url: string) => void
}

const UploadDropzone: React.FC<Props> = ({ onComplete }: Props) => {
  const { t } = useTranslation('')

  const [percent, setPercent] = useState(0)
  const [uploading, setUploading] = useState(false)
  const [failed, setFailed] = useState(false)
  const [file, setFile] = useState<File>()
  const [isDragActive, setIsDragActive] = useState(false)

  const accept = '.glb'

  useEffect(() => {
    if (file) {
      setUploading(true)
      upload({
        file,
        onProgress: ({ loaded, total }) => setPercent((100 * loaded) / total),
        onComplete: ({ url }) => {
          setUploading(false)
          onComplete(url)
        },
        onError: () => {
          setUploading(false)
          setFailed(true)
        },
      })
    }
  }, [file])

  const onAcceptedFiles = ([acceptedFile]: File[]) => setFile(acceptedFile)

  const dropzoneProps = {
    accept,
    onAcceptedFiles,
    setIsDragActive,
  }

  return (
    <Dropzone {...dropzoneProps}>
      {uploading ? (
        <div className="progress">
          <p>{t('dropzone.uploading')}</p>
          <Line percent={percent} />
        </div>
      ) : isDragActive ? (
        <p>{t('dropzone.drop')}</p>
      ) : (
        <p>{failed ? t('dropzone.failed') : t('dropzone.info')}</p>
      )}
    </Dropzone>
  )
}

export default UploadDropzone
